import { graphql } from 'gatsby';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

import { addAuthentication } from '../../components/addAuthentication';
import { DashboardPageHeading, HelpCard } from '../../components/dashboard';
import { DashboardLayout } from '../../components/layout';
import './scss/HelpPage.scss';

import {
  ResourceCenter,
  // PaymentIconBlue,
  // UserIconBlue,
} from '../../components/icons';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amwell Private Practice | Dashboard - Help</title>
    </Helmet>
    <DashboardLayout>
      <div className="HelpPage">
        <DashboardPageHeading
          heading={'Resource Center'}
          links={[
            {
              label: 'Resources & Contact Information',
              to: '/dashboard/help',
            },
          ]}
          partiallyActive={true}
        />

        <div className="HelpPage-container">
          <HelpCards />
          <div className="HelpPage-support">
            <h3 className="HelpPage-support-headline">Still Have Questions?</h3>
            <p className="HelpPage-support-copy">
              Please email{' '}
              <a href="emailto:private.practice@amwell.com">
                private.practice@amwell.com
              </a>{' '}
              for assistance with your account.
            </p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  </>
));

const HelpCards = () => {
  const glideRef = useRef(null);
  // const slider = useRef(null)
  // const sliderConfiguration = {
  //   draggable: true,
  //   dots: ".glide__bullets",
  //   type: "carousel",
  //   perView: 1,
  // }
  const cards = [
    <HelpCard
      icon={<ResourceCenter />}
      headline="Resource Center"
      copy="Access training resources, patient outreach materials, reimbursement guidelines and more."
      to="https://info.amwell.com/Amwell-Private-Practice-Training.html"
    />,
  ];

  // useEffect(() => {
  //   if (glideRef.current) {
  //     slider.current = new Glide(".glide", sliderConfiguration)
  //     slider.current.mount()
  //   }
  // }, [slider, sliderConfiguration])

  return (
    <>
      <div className="HelpPage-cards desktop">
        {cards.map((Card, i) => (
          <React.Fragment key={`card-${i}`}>{Card}</React.Fragment>
        ))}
      </div>

      <div className="HelpPage-cards glide mobile" ref={glideRef}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {cards.map((Card, i) => (
              <li className="glide__slide" key={`slide-${i}`}>
                {Card}
              </li>
            ))}
          </ul>
        </div>
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {/* {cards.map((_, i) => (
            <button
              className="glide__bullet"
              data-glide-dir={"=" + i}
              aria-label="slide bullet"
              key={`bullet-${i}`}
            ></button>
          ))} */}
        </div>
      </div>
    </>
  );
};
